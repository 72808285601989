import { createReducer, on } from '@ngrx/store';
import { countriesActions } from '../actions/countries.actions';
import { Country } from '../models';

export interface CountriesState {
    available: Country[] | null;
    all: Country[] | null;
    loading: boolean | null;
    loaded: boolean | null;
}

export const initialState: CountriesState = {
    available: null,
    all: null,
    loading: null,
    loaded: null,
};

export const countriesReducer = createReducer(
    initialState,
    on(
        countriesActions.getCountries,
        (state): CountriesState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        countriesActions.getCountriesSuccess,
        (state, action): CountriesState => ({
            ...state,
            available: action.payload[0],
            all: action.payload[1],
            loading: false,
            loaded: true,
        }),
    ),
    on(
        countriesActions.getCountriesError,
        (state): CountriesState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),

    on(
        countriesActions.clearLoaded,
        (state): CountriesState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        countriesActions.clear,
        (state): CountriesState => ({
            ...state,
            ...initialState,
        }),
    ),
);
