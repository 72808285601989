import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter, tap, switchMap, take } from 'rxjs';
import { CountriesFacade } from '../facades/countries.facade';

export const countriesLoadedGuard: CanActivateFn = () => {
    const countriesFacade = inject(CountriesFacade);
    return countriesFacade.loaded$.pipe(
        take(1),
        tap((loaded) => {
            if (loaded === null || loaded === false) {
                countriesFacade.getCountries();
            }
        }),
        switchMap(() => countriesFacade.loaded$),
        filter((loaded): loaded is true => loaded === true),
    );
};
